import moment from "moment";

export function removeSpecialChars(value) {
  return value.replace(/\W/g, "");
}

export function stringToDate(date) {
  return new Date(date);
}

export function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function statusHistoryName(item) {
  if (item.Subject === "ModalityData") {
    switch (item.Column) {
      case "Name":
        return "Nome da modalidade";

      case "MaxInsuredAmountValue":
        return "Valor máximo segurado";

      case "MaxInsuredAmountAutomaticValue":
        return "Valor máximo aprovado automaticamente";

      case "MinInsurancePremium":
        return "Prêmio mínimo";

      case "MaxRetroactivityDays":
        return "Max. dias retroativos";

      case "MaxFutureDays":
        return "Max. dias futuros";

      case "MinPeriodInDays":
        return "Vigência mínima (dias)";

      case "MaxPeriodInDays":
        return "Vigência máxima (dias)";

      case "Ref":
        return "Código de referêcia da modalidade";

      case "IsDeleted":
        return "Inativar modalidade";

      case "EnabledForAutomaticEmission":
        return "Modalidade habilitada para emissão automática";

      case "IsComplementaryModality":
        return "Esta modalidade está atuando como modalidade complementar";

      case "HasComplementaryModality":
        return "Esta modalidade permite a inclusão de modalidade complementar";

      case "DisabledForRestrictiveBranchActivity":
        return "Esta modalidade é restrita para os tomadores com ramos  restritos";

      case "EqualizeValidityDate":
        return "As vigências entre as modalidades e cobertura devem ser iguais";

      case "IgnoreBranchWhenInsuredIsPF":
        return "Permite emissão com segurado Pessoa Física";

      case "EnableAutomaticCancel":
        return "Cancelamento automático de apólices vencidas";

      case "DaysForAutomaticCancel":
        return "Nº dias após vencimento";

      case "CanUseModalityTax":
        return "Permite de uso de taxa por modalidade";

      default:
        return item.Column;
    }
  } else if (item.Subject === "Coverage") {
    switch (item.Column) {
      case "Name":
        return "Nome (Cobertura Adicional)";

      case "CoverageCalculationTypeId":
        return "Tipo de cálculo";

      case "CoverageCalculationJSONParam":
        return "Valor do cálculo";

      default:
        return item.Column;
    }
  } else {
    return item.Subject;
  }
}

export function dateToDDMMYYYY(date) {
  if (date instanceof Date) {
    date = date.setHours(0, 0, 0, 0);
  }

  return moment(date).format("DD/MM/YYYY");
}

export function stripHtml(html) {
  html = html.split("<br").join("\\n\\n<br");
  html = html.split("<p").join("\\n<p");

  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

export function diffDates(stringDateA, stringDateB) {
  if (stringDateA instanceof Date)
    stringDateA = stringDateA.setHours(0, 0, 0, 0);
  else stringDateA = new Date(stringDateA).setHours(0, 0, 0, 0);

  if (stringDateB instanceof Date)
    stringDateB = stringDateB.setHours(0, 0, 0, 0);
  else stringDateB = new Date(stringDateB).setHours(0, 0, 0, 0);

  var a = moment(stringDateA);
  var b = moment(stringDateB);
  return a.diff(b, "days");
}

export function addDaysToDate(stringDateA, days) {
  if (stringDateA instanceof Date)
    stringDateA = stringDateA.setHours(0, 0, 0, 0);
  else stringDateA = new Date(stringDateA).setHours(0, 0, 0, 0);

  let date = moment(stringDateA).add(days, "days");
  return date.format("YYYY-MM-DDTHH:mm:ss");
}

export function dateToDDMMYYYYHHmm(date) {
  return `${moment(date).format("DD/MM/YYYY")} às ${moment(date).format(
    "HH:mm"
  )}`;
}

export function maskCnpj(value) {
  const formattedDoc = value
    .replace(/\D/g, "")
    .replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5");

  return formattedDoc;
}

export function maskCpf(value) {
  if (value) {
    const formattedDoc = value
      .replace(/\D/g, "")
      .replace(/^(\d{3})?(\d{3})?(\d{3})?(\d{2})?/, "$1.$2.$3-$4");

    return formattedDoc;
  }
  return "";
}

export function maskDocument(value) {
  if (value) {
    const formattedDoc = value.replace(/\D/g, "");
    if (formattedDoc.length == 11)
      return formattedDoc.replace(
        /^(\d{3})?(\d{3})?(\d{3})?(\d{2})?/,
        "$1.$2.$3-$4"
      );

    return formattedDoc.replace(
      /^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/,
      "$1.$2.$3/$4-$5"
    );
  }
  return "";
}

export function validEmail(value) {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(value);
}

export function isValidCnpj(value) {
  let cnpj = value;
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj == "") return false;

  if (cnpj.length != 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj == "00000000000000" ||
    cnpj == "11111111111111" ||
    cnpj == "22222222222222" ||
    cnpj == "33333333333333" ||
    cnpj == "44444444444444" ||
    cnpj == "55555555555555" ||
    cnpj == "66666666666666" ||
    cnpj == "77777777777777" ||
    cnpj == "88888888888888" ||
    cnpj == "99999999999999"
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (var i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;

  return true;
}

export function isValidCpf(cpf) {
  if (typeof cpf !== "string") return false;

  cpf = cpf.replace(/[\s.-]*/gim, "");

  if (
    !cpf ||
    cpf.length != 11 ||
    cpf == "00000000000" ||
    cpf == "11111111111" ||
    cpf == "22222222222" ||
    cpf == "33333333333" ||
    cpf == "44444444444" ||
    cpf == "55555555555" ||
    cpf == "66666666666" ||
    cpf == "77777777777" ||
    cpf == "88888888888" ||
    cpf == "99999999999"
  ) {
    return false;
  }
  var soma = 0;
  var resto;
  for (var i = 1; i <= 9; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(cpf.substring(9, 10))) return false;
  soma = 0;
  for (var i = 1; i <= 10; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(cpf.substring(10, 11))) return false;
  return true;
}

export const moneyFormatOptions = {
  minimumFractionDigits: 2,
  style: "currency",
  currency: "BRL"
};

export const moneyConfig = {
  decimal: ",",
  thousands: ".",
  prefix: "R$ ",
  suffix: "",
  precision: 2,
  masked: false
};
export function numberToOrdinal(v) {
  if (v != undefined && !isNaN(v)) {
    return ["", "Primeiro", "Segundo", "Terceiro", "Quarto", "Quinto"][
      Math.abs(~[1, 2, 3, 4, 5].indexOf(+(+v).toFixed().substr(-1)))
    ];
  }
  return "";
}

export function reaisToExtenso(c) {
  var ex = [
    [
      "zero",
      "um",
      "dois",
      "três",
      "quatro",
      "cinco",
      "seis",
      "sete",
      "oito",
      "nove",
      "dez",
      "onze",
      "doze",
      "treze",
      "quatorze",
      "quinze",
      "dezesseis",
      "dezessete",
      "dezoito",
      "dezenove"
    ],
    [
      "dez",
      "vinte",
      "trinta",
      "quarenta",
      "cinqüenta",
      "sessenta",
      "setenta",
      "oitenta",
      "noventa"
    ],
    [
      "cem",
      "cento",
      "duzentos",
      "trezentos",
      "quatrocentos",
      "quinhentos",
      "seiscentos",
      "setecentos",
      "oitocentos",
      "novecentos"
    ],
    [
      "mil",
      "milhão",
      "bilhão",
      "trilhão",
      "quadrilhão",
      "quintilhão",
      "sextilhão",
      "setilhão",
      "octilhão",
      "nonilhão",
      "decilhão",
      "undecilhão",
      "dodecilhão",
      "tredecilhão",
      "quatrodecilhão",
      "quindecilhão",
      "sedecilhão",
      "septendecilhão",
      "octencilhão",
      "nonencilhão"
    ]
  ];

  var a,
    n,
    v,
    i,
    n = c.replace(/[^,\d]/g, "").split(","),
    e = " e ",
    $ = "real",
    d = "centavo",
    sl;
  for (
    var f = n.length - 1, l, j = -1, r = [], s = [], t = "";
    ++j <= f;
    s = []
  ) {
    j && (n[j] = (("." + n[j]) * 1).toFixed(2).slice(2));
    if (
      !((a = (v = n[j]).slice((l = v.length) % 3).match(/\d{3}/g)),
      (v = l % 3 ? [v.slice(0, l % 3)] : []),
      (v = a ? v.concat(a) : v)).length
    )
      continue;
    for (a = -1, l = v.length; ++a < l; t = "") {
      if (!(i = v[a] * 1)) continue;
      (i % 100 < 20 && (t += ex[0][i % 100])) ||
        ((i % 100) + 1 &&
          (t +=
            ex[1][(((i % 100) / 10) >> 0) - 1] +
            (i % 10 ? e + ex[0][i % 10] : "")));
      s.push(
        (i < 100
          ? t
          : !(i % 100)
          ? ex[2][i == 100 ? 0 : (i / 100) >> 0]
          : ex[2][(i / 100) >> 0] + e + t) +
          ((t = l - a - 2) > -1
            ? " " + (i > 1 && t > 0 ? ex[3][t].replace("ão", "ões") : ex[3][t])
            : "")
      );
    }
    a =
      (sl = s.length) > 1
        ? ((a = s.pop()), s.join(" ") + e + a)
        : s.join("") || ((!j && n[j + 1] * 1 > 0) || r.length ? "" : ex[0][0]);
    a &&
      r.push(
        a +
          (c
            ? " " +
              (v.join("") * 1 > 1
                ? j
                  ? d + "s"
                  : (/0{6,}$/.test(n[0]) ? "de " : "") + $.replace("l", "is")
                : j
                ? d
                : $)
            : "")
      );
  }
  return r.join(e);
}

export function formatStrMoney(
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    const i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {}
}

export function validChanged(value = {}, valueCompare = {}, ignoreKeys = []) {
  if (!value || !valueCompare) return false;

  let wasChange = false;

  if (!(value instanceof Array))
    Object.keys(value).forEach(key => {
      let isDiferent = false;

      if (valueCompare[key] != value[key] && !ignoreKeys.includes(key)) {
        if (value[key] instanceof Date) {
          if (diffDates(value[key], valueCompare[key])) {
            wasChange = true;
            isDiferent = true;
          }
        } else {
          if (value[key] instanceof Array) {
            for (var i in value[key]) {
              Object.keys(value[key][i]).forEach(subKey => {
                if (
                  (value[key][i] || {})[subKey] !=
                  (valueCompare[key][i] || {})[subKey]
                ) {
                  wasChange = true;
                  isDiferent = true;
                }
              });
            }

            if (!isDiferent) delete value[key];
          } else if (value[key] instanceof Object) {
            Object.keys(value[key]).forEach(subKey => {
              if (valueCompare[key][subKey] != value[key][subKey]) {
                wasChange = true;
                isDiferent = true;
              }
            });
          } else {
            wasChange = true;
            isDiferent = true;
          }
        }
      }

      if (!isDiferent && !!value[key] && !ignoreKeys.includes(key))
        delete value[key];
    });
  else if (value instanceof Array) {
    for (var i in value) {
      Object.keys(value[i]).forEach(Key => {
        if ((value[i] || {})[Key] != (valueCompare[i] || {})[Key]) {
          wasChange = true;
        }
      });
    }
  }

  return wasChange;
}

export function clearObjectAttributes(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (Array.isArray(obj[key])) {
        obj[key].length = 0;
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        clearObjectAttributes(obj[key]);
      } else {
        obj[key] = null;
      }
    }
  }
}


export function validPhoneNumber(value) {
  return /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$/.test(value);
}
export function validName(value) {
  return /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]+$/.test(value);
}

export function validPhone(value) {
  return /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$/.test(value);
}
